<template>
	<div class="bg-F7F7F7">
		<div class="w1200 margin-center h68 justify-content-center-between fz20 co-333333"></div>

		<div class="w1200 margin-center bg-white">
			<div class="justify-content-center-between mart10">
				<div class="school-item center flex-column " v-for="(item, i) in list" :key="i">
					<img class="school-pic display-block" :src="item.image" alt="" />
					<span class="mart12 fz14 co-666666 display-block mart12  text-center paddlr20">{{item.name}}</span>
				</div>
			</div>
		</div>
		<loadMore :state="state" loading_icon></loadMore>
		<div class="center paddt54">
			<el-pagination background class="center " layout="prev, pager, next, jumper" :total="list.length"
				:page-size="size">
			</el-pagination>
		</div>
	</div>

</template>

<script>
	import {
		useRoute
	} from 'vue-router';
	export default {
		name: 'ServiceItem',

		data() {
			return {
				size: 10,
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
			}
		},
		created() {
			let data = useRoute().query;

			this.list = JSON.parse(data.data)
			if (this.list.length > 0) {
				this.state = 0;
			} else {
				this.state = 3;
			}
		},
		methods: {}
	}
</script>

<style lang="less" scoped>
	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999 !important;
	}

	.btn-prev,
	.btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}

	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
